/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI PRO - v2.0.0 Beta
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import {
  Box,
  Button,
  Link,
  Icon,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets

import { TextLine } from "components/icons/Icons";
import image from "assets/img/pro/overview/image.png";
import image1 from "assets/img/pro/overview/image-1.png";
import image2 from "assets/img/pro/overview/image-2.png";
import image3 from "assets/img/pro/overview/image-3.png";
import image4 from "assets/img/pro/overview/image-4.png";
import image5 from "assets/img/pro/overview/image-5.png";
import image6 from "assets/img/pro/overview/image-6.png";
import image7 from "assets/img/pro/overview/image-7.png";
import image8 from "assets/img/pro/overview/image-8.png";
import image9 from "assets/img/pro/overview/image-9.png";
import image10 from "assets/img/pro/overview/image-10.png";
import image11 from "assets/img/pro/overview/image-11.png";
import { MdChevronRight } from "react-icons/md";

// Custom components
import InnerContent from "layouts/innerContent";
import PageCard from "./components/PageCard";

export default function Overview() {
  // Chakra Color Mode
  const brandColorPrice = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("#120F43", "white");
  const gradient = useColorModeValue(
    "linear-gradient(360deg, #ffffff 30%, rgba(247, 250, 252, 0) 100%)",
    "linear-gradient(360deg, #0B1437 5%, rgba(28, 31, 57, 0) 100%)"
  );
  const baseGradient = useColorModeValue(
    "linear-gradient(360deg, #ffffff 75%, rgba(247, 250, 252, 0) 100%)",
    "linear-gradient(360deg, #0B1437 5%, rgba(28, 31, 57, 0) 100%)"
  );
  return (
    <Flex
      w="100%"
      direction={{ base: "column" }}
      overflow="hidden"
      bgSize="cover"
      position="relative"
      zIndex="2"
      pb={{ base: "70px", md: "90px" }}
    >
      <InnerContent px={{ base: "20px", md: "40px", xl: "0px" }}>
        <Flex direction="column" width="stretch">
          <Flex
            direction="column"
            mx="auto"
            mb={{ base: "50px", md: "80px" }}
            alignItems="center"
            textAlign="center"
          >
            <Text
              as="h3"
              textAlign={{ base: "center", lg: "center" }}
              fontWeight="700"
              letterSpacing="2px"
              color={brandColorPrice}
              fontSize={{ base: "xs", md: "md" }}
              w="100%"
              mb="10px"
            >
              PAGES AND COMPONENTS OVERVIEW
            </Text>
            <Text
              as="h2"
              position="relative"
              textAlign={{ base: "center" }}
              color={textColor}
              fontWeight="800"
              fontSize={{ base: "34px", md: "48px", lg: "48px", xl: "58px" }}
              lineHeight={{ base: "44px", md: "60px", lg: "60px", xl: "70px" }}
              mb={{ base: "20px", md: "30px" }}
              w={{ base: "100%", md: "90%", lg: "60%", xl: "60%" }}
              mx="auto"
            >
              Boost your productivity with ready to use pages{" "}
              <Icon
                position={"absolute"}
                as={TextLine}
                bottom={{
                  base: "-20px",
                  md: "-32px",
                  lg: "-30px",
                  xl: "-36px",
                }}
                left={{ base: "94%", md: "50%" }}
                transform={{
                  base: "translate(-94%, 0px)",
                  md: "translate(-50%, 0px)",
                }}
                w={{ base: "180px", md: "300px", xl: "350px" }}
              />
              <Icon
                position={"absolute"}
                as={TextLine}
                bottom={{
                  base: "-20px",
                  md: "-32px",
                  lg: "-30px",
                  xl: "-36px",
                }}
                filter="blur(15px)"
                left={{ base: "94%", md: "50%" }}
                transform={{
                  base: "translate(-94%, 0px)",
                  md: "translate(-50%, 0px)",
                }}
                w={{ base: "180px", md: "300px", xl: "350px" }}
              />
            </Text>
            <Text
              color="gray.600"
              fontSize={{ base: "md", md: "md", xl: "lg" }}
              fontWeight="500"
              letterSpacing="0px"
              lineHeight={{ base: "24px", md: "30px" }}
              w={{ base: "100%", md: "90%", lg: "80%", xl: "72%" }}
            >
              Improve your development process with a set of 44+ ready to use
              react pages! Trendy page categories like Dashboards, Login pages,
              NFTs pages and many more are available to save your important
              time!
            </Text>
          </Flex>
        </Flex>
        <SimpleGrid
          columns={{ base: "1", md: "2", lg: "4" }}
          gap="20px"
          w={{ base: "100%", md: "100%" }}
          mb="20px"
        >
          <PageCard
            altImage="react dashboard example page"
            title="Main Dashboard"
            image={image}
            link="https://horizon-ui.com/chakra-pro/admin/dashboards/default"
          />
          <PageCard
            altImage="react smart home example page"
            title="Smart Home"
            image={image1}
            link="https://horizon-ui.com/chakra-pro/admin/dashboards/smart-home"
          />
          <PageCard
            altImage="react car interface example page"
            title="Car Interface"
            image={image2}
            link="https://horizon-ui.com/chakra-pro/admin/dashboards/car-interface"
          />
          <PageCard
            altImage="react profile overview example page"
            title="Profile Overview"
            image={image3}
            link="https://horizon-ui.com/chakra-pro/admin/main/profile/overview"
          />
          <PageCard
            altImage="react nft marketplace example page"
            title="NFT Marketplace"
            image={image4}
            link="https://horizon-ui.com/chakra-pro/admin/nfts/marketplace"
          />
          <PageCard
            altImage="react nft example page"
            title="NFT Page"
            image={image5}
            link="https://horizon-ui.com/chakra-pro/admin/nfts/marketplace"
          />
          <PageCard
            altImage="react nft collection example page"
            title="NFT Collection"
            image={image6}
            link="https://horizon-ui.com/chakra-pro/admin/nfts/collection"
          />
          <PageCard
            altImage="react nft profile example page"
            title="NFT Profile"
            image={image7}
            link="https://horizon-ui.com/chakra-pro/admin/nfts/profile"
          />
        </SimpleGrid>
        <Box position="relative" w="100%">
          <Flex
            direction={"column"}
            position="absolute"
            h="95px"
            w="100%"
            zIndex="3"
            justifyContent="center"
            align="center"
            bottom={{ base: "50px", md: "0px" }}
          >
            {" "}
            <Text
              color="#4A5568"
              fontSize="sm"
              letterSpacing="0px"
              fontWeight="500"
              mb="20px"
            >
              Looking to see all pages or to get started?
            </Text>
            <Flex mx="auto" direction={{ base: "column", md: "row" }}>
              <Link
                w={{ base: "100%", md: "unset" }}
                isExternal
                href="https://horizon-ui.com/chakra-pro"
              >
                <Button
                  variant="silver"
                  py="20px"
                  w={{ base: "100%", md: "unset" }}
                  px="30px"
                  fontSize="sm"
                  borderRadius="45px"
                  me={{ base: "0px", md: "14px" }}
                  mb={{ base: "20px", md: "0px" }}
                  h="54px"
                >
                  See all pages live
                </Button>
              </Link>
              <Link href="#pricing">
                <Button
                  variant="primary"
                  py="20px"
                  px="16px"
                  fontSize="sm"
                  borderRadius="45px"
                  me={{ base: "0px", md: "14px" }}
                  mb={{ base: "20px", md: "0px" }}
                  w={{ base: "300px", md: "210px" }}
                  h="54px"
                >
                  Get started with PRO
                  <Icon as={MdChevronRight} color="white" h="16px" w="16px" />
                </Button>
              </Link>
            </Flex>
          </Flex>
          <Flex
            position="absolute"
            transform={{
              base: "translate(-222px, -300px)",
              md: "translate(-1500px, 0px)",
              lg: "translate(-1500px, -330px)",
            }}
            h={{ base: "660px", md: "660px" }}
            w="300vw"
            zIndex="1"
            bg={{ base: baseGradient, md: gradient }}
          />
          <SimpleGrid
            columns={{ base: "1", md: "2", lg: "4" }}
            gap="20px"
            w="100%"
          >
            {" "}
            <PageCard
              altImage="react kanban example page"
              display={{ base: "none", md: "block" }}
              title="Kanban Page"
              image={image8}
              link="link"
            />
            <PageCard
              altImage="react billing example page"
              display={{ base: "none", md: "block" }}
              title="Billing Page"
              image={image9}
              link="link"
            />
            <PageCard
              altImage="react applications example page"
              display={{ base: "none", md: "block" }}
              title="Application"
              image={image10}
              link="link"
            />
            <PageCard
              altImage="react course example page"
              display={{ base: "none", md: "block" }}
              title="Course Page"
              image={image11}
              link="link"
            />
          </SimpleGrid>
        </Box>
      </InnerContent>
    </Flex>
  );
}
