// Chakra Imports
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Badge,
	Box,
	Button,
	Flex,
	Icon,
	Image,
	Link,
	ListItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	UnorderedList,
	useColorModeValue,
} from "@chakra-ui/react";
// import { HorizonUI2Free } from "components/icons/Icons";
import horizonuilogomain from "assets/img/pro/layout/horizonuilogomain.png";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { IoChevronDown, IoMenuOutline } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";

function formatNumber(number, decPlaces) {
	decPlaces = Math.pow(10, decPlaces);

	const abbrev = ["K", "M", "B", "T"];

	for (let i = abbrev.length - 1; i >= 0; i--) {
		var size = Math.pow(10, (i + 1) * 3);

		if (size <= number) {
			number = Math.round((number * decPlaces) / size) / decPlaces;

			if (number == 1000 && i < abbrev.length - 1) {
				number = 1;
				i++;
			}

			number += abbrev[i];

			break;
		}
	}

	return number;
}
export default function AdminNavbar(props) {
	const [scrolled, setScrolled] = useState(false);
	const [stars, setStars] = useState(0);

	useEffect(() => {
		window.addEventListener("scroll", changeNavbar);

		return () => {
			window.removeEventListener("scroll", changeNavbar);
		};
	});
	useEffect(() => {
		const response = fetch(
			"https://api.github.com/repos/horizon-ui/horizon-ui-chakra"
		)
			.then((response) => response.json())
			.then((data) => setStars(formatNumber(data.stargazers_count, 1)));
	}, []);

	const { secondary, message } = props;

	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let textColor = useColorModeValue("secondaryGray.900", "white");
	let borderColor = useColorModeValue("gray.300", "white");
	let navbarPosition = "fixed";
	let navbarFilter = "none";
	let navbarBackdrop = "blur(20px)";
	let navbarShadow = "45px 76px 113px 7px rgba(112, 144, 176, 0.08)";
	let navbarBg = "white";
	let navbarBorder = "transparent";
	let paddingX = "15px";
	let gap = "0px";
	let navbarTop = "0px";
	let menuBg = useColorModeValue("white", "navy.800");
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};
	if (!scrolled) navbarBackdrop = "unset";
	if (!scrolled) navbarTop = "-70px";
	return (
		<Box
			position={navbarPosition}
			boxShadow={navbarShadow}
			bg={navbarBg}
			borderColor={navbarBorder}
			filter={navbarFilter}
			backdropFilter={navbarBackdrop}
			backgroundPosition='center'
			backgroundSize='cover'
			zIndex='200'
			borderStyle='solid'
			transitionDelay='0s, 0s, 0s, 0s'
			transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
			transition-property='box-shadow, background-color, filter, border'
			transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: "center" }}
			display={secondary ? "block" : "flex"}
			minH='68px'
			justifyContent={{ xl: "center" }}
			lineHeight='25.6px'
			mx='auto'
			py='8px'
			left='50%'
			transform='translate(-50%,0px)'
			w='100%'
			top={navbarTop}>
			<Flex
				w={{
					base: "calc(100vw - 4%)",
					md: "calc(100vw - 4%)",
					lg: "100vw",
					xl: "calc(100vw - 250px)",
					"2xl": "1200px",
				}}
				px={{
					sm: paddingX,
					md: "10px",
					lg: "12px",
				}}
				ps={{
					xl: "12px",
				}}
				flexDirection={{
					sm: "row",
					md: "row",
				}}
				alignItems='center'
				justify='space-between'
				mb={gap}>
				<Link
					display={"flex"}
					alignItems='center'
					justifyContent={"center"}
					href='/pro'>
					<Image
						alt='horizon ui pro - premium react admin dashboard template'
						me='10px'
						w='179px'
						src={horizonuilogomain}
					/>
					{/* <Icon
            as={HorizonUI2Free}
            color={textColor}
            w="179px"
            h="39px"
            me="10px"
          /> */}
					<Badge
						display={{ base: "flex", lg: "none", xl: "flex" }}
						colorScheme='brand'
						bg='linear-gradient(15.46deg, #4A25E1 26.3%, #9C84FF 91.13%)'
						borderRadius='25px'
						color='white'
						textTransform={"none"}
						letterSpacing='0px'
						px='12px'>
						PRO
					</Badge>
				</Link>
				<Flex>
					<Link
						display={{ base: "none", lg: "block" }}
						href='https://horizon-ui.com/components'
						color='gray.600'
						fontSize='sm'
						fontWeight='500'
						letterSpacing='0px'
						me='30px'
						my='auto'>
						Components
					</Link>

					<Menu>
						<MenuButton
							display={{ base: "none", lg: "flex" }}
							flexDirection='row !important'
							p='0px !important'>
							{" "}
							<Text
								display={{ base: "none", lg: "flex" }}
								alignItems='center'
								color='gray.600'
								fontSize='sm'
								fontWeight='500'
								me='20px'
								my='auto'>
								Documentation
								<Icon ms='6px' as={IoChevronDown} />
							</Text>
						</MenuButton>
						<MenuList p='0px' mt='0px' borderRadius='10px' bg={menuBg}>
							<Flex flexDirection='column' p='10px'>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://horizon-ui.com/docs-tailwind'
										color='gray.600'
										fontSize='sm'
										fontWeight='500'
										isExternal='true'
										me='10px'
										my='auto'>
										Tailwind CSS React
									</Link>
								</MenuItem>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://horizon-ui.com/documentation/docs/introduction'
										color='gray.600'
										fontSize='sm'
										isExternal='true'
										fontWeight='500'
										me='10px'
										my='auto'>
										Chakra UI Docs
									</Link>
								</MenuItem>
							</Flex>
						</MenuList>
					</Menu>
					<Link
						display={{ base: "none", lg: "block" }}
						href='#pricing'
						color='gray.600'
						fontSize='sm'
						fontWeight='500'
						letterSpacing='0px'
						me='30px'
						my='auto'>
						Pricing
					</Link>
					<Menu>
						<MenuButton
							display={{ base: "none", lg: "flex" }}
							flexDirection='row !important'
							p='0px !important'>
							{" "}
							<Text
								display={{ base: "none", lg: "flex" }}
								alignItems='center'
								color='gray.600'
								fontSize='sm'
								fontWeight='500'
								me='20px'
								my='auto'>
								Resources
								<Icon ms='6px' as={IoChevronDown} />
							</Text>
						</MenuButton>
						<MenuList p='0px' mt='0px' borderRadius='10px' bg={menuBg}>
							<Flex flexDirection='column' p='10px'>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://horizon-ui.com/boilerplate'
										color='gray.600'
										fontSize='sm'
										fontWeight='500'
										isExternal='true'
										me='10px'
										my='auto'>
										Boilerplate
									</Link>
								</MenuItem>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://horizon-ui.com/pro-corporate'
										color='gray.600'
										fontSize='sm'
										fontWeight='500'
										isExternal='true'
										me='10px'
										my='auto'>
										Corporate Version
									</Link>
								</MenuItem>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://horizon-ui.com/ai-template'
										color='gray.600'
										fontSize='sm'
										fontWeight='500'
										isExternal='true'
										me='10px'
										my='auto'>
										AI Template
									</Link>
								</MenuItem>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://horizon-ui.com/landing-kit'
										color='gray.600'
										fontSize='sm'
										fontWeight='500'
										isExternal='true'
										me='10px'
										my='auto'>
										Landing Kit
									</Link>
								</MenuItem>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://horizon-ui.com/app-generator'
										color='gray.600'
										fontSize='sm'
										fontWeight='500'
										isExternal='true'
										me='10px'
										my='auto'>
										App Generator
									</Link>
								</MenuItem>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://horizon-ui.com/custom-development'
										color='gray.600'
										fontSize='sm'
										fontWeight='500'
										isExternal='true'
										me='10px'
										my='auto'>
										Custom Development
									</Link>
								</MenuItem>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://blog.horizon-ui.com'
										color='gray.600'
										fontSize='sm'
										isExternal='true'
										fontWeight='500'
										me='10px'
										my='auto'>
										Blog
									</Link>
								</MenuItem>
								<MenuItem
									_hover={{ bg: "#EDF2F7" }}
									transition='0.1s linear'
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='#faqs'
										color='gray.600'
										fontSize='sm'
										fontWeight='500'
										me='10px'
										my='auto'>
										FAQs
									</Link>
								</MenuItem>
							</Flex>
						</MenuList>
					</Menu>
					<Menu>
						<MenuButton
							display={{ base: "block", xl: "none" }}
							p='0px !important'
							maxH='20px'
							maxW='20px'
							alignContent='end'>
							<Icon
								display={{ base: "block", lg: "none" }}
								as={IoMenuOutline}
								color={textColor}
								w='20px'
								h='20px'
								_hover={{ cursor: "pointer" }}
							/>
						</MenuButton>
						<MenuList
							p='0px'
							mt='10px'
							borderRadius='10px'
							border='1px solid'
							borderColor='#CBD5E0'
							bg={menuBg}>
							<Flex flexDirection='column' p='10px'>
								<MenuItem
									_hover={{ bg: "none" }}
									_focus={{ bg: "none" }}
									borderRadius='8px'
									px='14px'>
									<Link
										href='https://horizon-ui.com/components'
										color='gray.600'
										fontSize='md'
										fontWeight='500'
										me='30px'
										my='auto'>
										Components
									</Link>
								</MenuItem>
								<Accordion allowMultiple>
									<AccordionItem border='0px' p='0px'>
										<AccordionButton
											display={"flex"}
											flexDirection='column'
											outline={"none"}
											px='14px'
											_focus={{
												border: "none",
												outline: "none",
												boxShadow: "none",
												background: "transparent !important",
											}}>
											<Text
												display={"flex"}
												alignItems='center'
												w='100%'
												color='gray.600'
												fontSize='md'
												fontWeight='500'
												textAlign={"start"}>
												Documentation
												<Icon ms='8px' as={IoChevronDown} />
											</Text>
										</AccordionButton>
										<AccordionPanel display='flex' flexDirection={"column"}>
											<UnorderedList>
												<ListItem mb='10px'>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='https://horizon-ui.com/docs-tailwind'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															Tailwind CSS React
														</Link>
													</MenuItem>
												</ListItem>
												<ListItem>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='https://horizon-ui.com/documentation/docs/introduction'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															Chakra UI Docs
														</Link>
													</MenuItem>
												</ListItem>
											</UnorderedList>
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
								<MenuItem
									_hover={{ bg: "none" }}
									_focus={{ bg: "none" }}
									color='red.400'
									borderRadius='8px'>
									<Link
										href='#pricing'
										color='gray.600'
										fontSize='md'
										fontWeight='500'
										me='30px'
										my='auto'>
										Pricing
									</Link>
								</MenuItem>
								<Accordion allowMultiple>
									<AccordionItem border='0px' p='0px'>
										<AccordionButton
											display={"flex"}
											flexDirection='column'
											outline={"none"}
											px='14px'
											_focus={{
												border: "none",
												outline: "none",
												boxShadow: "none",
												background: "transparent !important",
											}}>
											<Text
												display={"flex"}
												alignItems='center'
												w='100%'
												color='gray.600'
												fontSize='md'
												fontWeight='500'
												textAlign={"start"}>
												Resources
												<Icon ms='8px' as={IoChevronDown} />
											</Text>
										</AccordionButton>
										<AccordionPanel display='flex' flexDirection={"column"}>
											<UnorderedList>
												<ListItem mb='10px'>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='https://horizon-ui.com/boilerplate'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															Boilerplate
														</Link>
													</MenuItem>
												</ListItem>
												<ListItem mb='10px'>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='https://horizon-ui.com/pro-corporate'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															Corporate Version
														</Link>
													</MenuItem>
												</ListItem>
												<ListItem mb='10px'>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='https://horizon-ui.com/ai-template'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															AI Template
														</Link>
													</MenuItem>
												</ListItem>
												<ListItem mb='10px'>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='https://horizon-ui.com/landing-kit'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															Landing Kit
														</Link>
													</MenuItem>
												</ListItem>
												<ListItem mb='10px'>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='https://horizon-ui.com/app-generator'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															App Generator
														</Link>
													</MenuItem>
												</ListItem>
												<ListItem mb='10px'>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='https://horizon-ui.com/custom-development'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															Custom Development
														</Link>
													</MenuItem>
												</ListItem>
												<ListItem mb='10px'>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='https://blog.horizon-ui.com'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															Blog
														</Link>
													</MenuItem>
												</ListItem>
												<ListItem>
													<MenuItem
														_hover={{ bg: "none" }}
														_focus={{ bg: "none" }}
														borderRadius='8px'
														px='14px'>
														<Link
															href='#faqs'
															color='gray.600'
															fontSize='md'
															fontWeight='500'
															my='auto'>
															FAQs
														</Link>
													</MenuItem>
												</ListItem>
											</UnorderedList>
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
								<MenuItem
									_hover={{ bg: "none" }}
									_focus={{ bg: "none" }}
									color='red.400'
									borderRadius='8px'>
									<Link
										isExternal
										href='https://horizon-ui.com/chakra-pro'
										color='gray.600'
										fontSize='md'
										fontWeight='500'
										me='18px'
										my='auto'
										letterSpacing='0px'>
										Live Preview
									</Link>
								</MenuItem>
								<MenuItem
									_hover={{ bg: "none" }}
									_focus={{ bg: "none" }}
									borderRadius='8px'>
									<Link href='#pricing'>
										<Button
											variant='transparent'
											border='1px solid'
											borderColor={borderColor}
											color={textColor}
											fontSize='md'
											borderRadius='45px'
											bg='transparent'
											my='auto'>
											Get started with PRO
											<Icon as={MdChevronRight} ms='5px' h='16px' w='16px' />
										</Button>
									</Link>
								</MenuItem>
							</Flex>
						</MenuList>
					</Menu>
				</Flex>
				<Flex display={{ base: "none", lg: "flex" }}>
					<Link
						isExternal
						href='https://horizon-ui.com/chakra-pro'
						color='gray.600'
						fontSize='sm'
						fontWeight='500'
						me='18px'
						letterSpacing='0px'
						my='auto'>
						Live Preview
					</Link>
					<Link href='#pricing'>
						<Button
							variant='transparent'
							border='1px solid'
							borderColor={borderColor}
							color={textColor}
							fontSize='sm'
							borderRadius='45px'
							px='18px'
							py='12px'
							bg='transparent'
							my='auto'>
							Get started with PRO
							<Icon as={MdChevronRight} h='16px' w='16px' />
						</Button>
					</Link>
				</Flex>
			</Flex>
			{secondary ? <Text color='white'>{message}</Text> : null}
		</Box>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func,
};
