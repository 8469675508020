/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v2.0.0 Beta
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)


=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Avatar, AvatarGroup, Flex, Icon, Text } from "@chakra-ui/react";
// Assets
// Custom components
import InnerContent from "layouts/innerContent";
import React from "react";
import {
  CALCOM,
  CISCO,
  ENVATO,
  INFOSYS,
  MICROSOFT,
  SAMSUNG,
} from "components/icons/Icons";
import { IoIosStar } from "react-icons/io";
import avatar1 from "assets/img/pro/upgrade/avatars/alex-avatar-pro-1.png";
import avatar2 from "assets/img/pro/upgrade/avatars/paul-avatar-pro-2.png";
import avatar3 from "assets/img/pro/upgrade/avatars/elisa-avatar-pro-3.png";
import avatar4 from "assets/img/pro/upgrade/avatars/rares-avatar-pro-4.png";
import avatar5 from "assets/img/pro/upgrade/avatars/lee-avatar-pro-5.png";
import avatar6 from "assets/img/pro/upgrade/avatars/adi-avatar-pro-6.png";

export default function Hero() {
  // Chakra Color Mode
  return (
    <Flex
      w="100%"
      direction="column"
      pt={{
        base: "30px",
        md: "70px",
        xl: "70px",
        "2xl": "70px",
        "3xl": "70px",
      }}
      pb={{ base: "80px", md: "130px", xl: "130px" }}
      position="relative"
    >
      <InnerContent
        w="1170px"
        maxW="100%"
        px={{ base: "0px", md: "40px", xl: "0px" }}
        zIndex="2"
      >
        <Text
          color="white"
          textAlign={{ base: "center" }}
          fontWeight={"700"}
          fontSize={{ base: "xs", md: "md" }}
          letterSpacing="2px"
          mb="40px"
        >
          JOIN 30,000+ DEVELOPERS & BUSINESSES THAT USE HORIZON UI
        </Text>
        <Flex
          flexWrap={{ base: "wrap", xl: "nowrap" }}
          justify="center"
          alignItems="center"
          gap="60px"
          mb="40px"
        >
          <Icon
            w={{ base: "130px", md: "161px" }}
            h={{ base: "36px", md: "26px" }}
            as={SAMSUNG}
          />
          <Icon
            w={{ base: "130px", md: "160px" }}
            h={{ base: "36px", md: "34px" }}
            as={MICROSOFT}
          />
          <Icon
            w={{ base: "130px", md: "160px" }}
            h={{ base: "36px", md: "34px" }}
            as={CALCOM}
          />
          <Icon
            w={{ base: "86px", md: "97px" }}
            h={{ base: "36px", md: "52px" }}
            as={CISCO}
          />
          <Icon
            w={{ base: "130px", md: "160px" }}
            h={{ base: "36px", md: "31px" }}
            as={ENVATO}
          />
          <Icon
            w={{ base: "100px", md: "117px" }}
            h={{ base: "36px", md: "48px" }}
            as={INFOSYS}
          />
        </Flex>
        <Flex gap="10px" direction={{ base: "column", md: "row" }}>
          <AvatarGroup spacing="-16px" size="md">
            <Avatar
              showBorder="true"
              bg="#060428"
              width="50px"
              height="50px"
              borderWidth="4px"
              borderColor="#060428"
              src={avatar1}
            />
            <Avatar
              showBorder="true"
              bg="#060428"
              width="50px"
              height="50px"
              borderWidth="4px"
              borderColor="#060428"
              src={avatar2}
            />
            <Avatar
              showBorder="true"
              bg="#060428"
              width="50px"
              height="50px"
              borderWidth="4px"
              borderColor="#060428"
              src={avatar3}
            />
            <Avatar
              showBorder="true"
              bg="#060428"
              width="50px"
              height="50px"
              borderWidth="4px"
              borderColor="#060428"
              src={avatar4}
            />
            <Avatar
              showBorder="true"
              bg="#060428"
              width="50px"
              height="50px"
              borderWidth="4px"
              borderColor="#060428"
              src={avatar5}
            />
            <Avatar
              showBorder="true"
              bg="#060428"
              width="50px"
              height="50px"
              borderWidth="4px"
              borderColor="#060428"
              src={avatar6}
            />
          </AvatarGroup>
          <Flex direction={"column"} justify={{ base: "center", md: "start" }}>
            <Flex justify={{ base: "center", md: "start" }} alignItems="center">
              <Icon as={IoIosStar} w="22px" h="22px" color="#F6AD55" />
              <Icon as={IoIosStar} w="22px" h="22px" color="#F6AD55" />
              <Icon as={IoIosStar} w="22px" h="22px" color="#F6AD55" />
              <Icon as={IoIosStar} w="22px" h="22px" color="#F6AD55" />
              <Icon
                as={IoIosStar}
                w="22px"
                h="22px"
                color="orange.300"
                me="8px"
              />
              <Text color="white" fontWeight="bold" fontSize="lg">
                4.8
              </Text>
            </Flex>
            <Text
              color="white"
              textAlign={{ base: "center", md: "start" }}
              fontWeight="500"
              letterSpacing="0px"
              fontSize="md"
            >
              From 260+ reviews
            </Text>
          </Flex>
        </Flex>
      </InnerContent>
    </Flex>
  );
}
