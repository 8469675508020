/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI PRO - v2.0.0 Beta
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import {
  Badge,
  Box,
  Flex,
  Button,
  Icon,
  Link,
  Image,
  Text,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

// Assets
import { TextLine } from "components/icons/Icons";
import example1 from "assets/img/pro/started/image-example-1.png";
import example2 from "assets/img/pro/started/image-example-2.png";
import colors from "assets/img/pro/started/color-presets-configurator.png";
import contrast from "assets/img/pro/started/contrast-congfigurator.png";
import main from "assets/img/pro/started/main-configurator.png";
import fullscreen from "assets/img/pro/started/fullscreen-configurator.png";
import {
  MdChevronRight,
  MdTrendingUp,
  MdBolt,
  MdOutlineMonetizationOn,
  MdLayers,
  MdInsights,
  MdDevices,
  MdAddTask,
} from "react-icons/md";

// Custom components
import InnerContent from "layouts/innerContent";

export default function GetStarted() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("#120F43", "white");
  return (
    <Flex
      w="100%"
      direction={{ base: "column" }}
      pt={{ base: "90px", md: "90px", xl: "90px" }}
      pb={{ base: "90px", md: "200px", xl: "220px" }}
      overflow="hidden"
      bgSize="cover"
      bg="linear-gradient(180deg, #F5F7FA 0%, rgba(255, 255, 255, 0) 14.26%)"
      position="relative"
    >
      <InnerContent w="100%" px={{ base: "20px", md: "40px", xl: "0px" }}>
        {/* Header */}
        <Flex
          direction="column"
          mx="auto"
          mb={{ base: "50px", md: "30px", lg: "80px" }}
          alignItems="center"
          justify={"center"}
          textAlign="center"
        >
          <Text
            as="h3"
            textAlign={{ base: "center", lg: "center" }}
            fontWeight="700"
            letterSpacing="2px"
            color={brandColor}
            fontSize={{ base: "xs", md: "md" }}
            w="100%"
            mb="10px"
          >
            WORKFLOW LIKE NEVER BEFORE
          </Text>
          <Text
            as="h2"
            textAlign={{ base: "center" }}
            color={textColor}
            fontWeight="800"
            fontSize={{ base: "30px", md: "48px", lg: "48px", xl: "58px" }}
            lineHeight={{ base: "38px", md: "60px", lg: "60px", xl: "70px" }}
            mb={{ base: "20px", md: "30px" }}
            w={{ base: "80%", md: "80%", lg: "80%", xl: "85%" }}
            mx="auto"
          >
            Save{" "}
            <Text as="span" position={"relative"}>
              thousands
              <Icon
                position={"absolute"}
                as={TextLine}
                bottom={{
                  base: "-16px",
                  md: "-28px",
                  lg: "-30px",
                  xl: "-36px",
                }}
                left={{ base: "0%", md: "68%", lg: "50%" }}
                transform={{
                  base: "translate(0%, 0px)",
                  md: "translate(-66%, 0px)",
                  lg: "translate(-50%, 0px)",
                }}
                w={{ base: "150px", md: "270px", xl: "270px" }}
              />
              <Icon
                position={"absolute"}
                as={TextLine}
                bottom={{
                  base: "-16px",
                  md: "-28px",
                  lg: "-30px",
                  xl: "-36px",
                }}
                filter="blur(15px)"
                left={{ base: "0%", md: "68%", lg: "50%" }}
                transform={{
                  base: "translate(0%, 0px)",
                  md: "translate(-66%, 0px)",
                  lg: "translate(-50%, 0px)",
                }}
                w={{ base: "150px", md: "270px", xl: "270px" }}
              />
            </Text>{" "}
            of coding hours
          </Text>
          <Text
            color="gray.600"
            fontSize={{ base: "md", md: "md", xl: "lg" }}
            fontWeight="500"
            letterSpacing="0px"
            lineHeight={{ base: "24px", md: "30px" }}
            w={{ base: "100%", md: "90%", lg: "90%", xl: "80%" }}
            mb="10px"
          >
            We know you're tired of wasting thousands of hours of coding &
            design starting from scratch on every project and trying to build
            the best components & elements, we've thought of everything so you
            don't have to.
          </Text>
        </Flex>
        {/* Speed */}
        <Flex
          direction={{ base: "column", lg: "row" }}
          mb={{ base: "80px", xl: "0px" }}
          width="100%"
        >
          <Flex
            direction="column"
            width="stretch"
            my="auto"
            alignItems={{ base: "center", md: "left" }}
          >
            <Box w="100%">
              <Text
                as="h3"
                fontWeight="700"
                letterSpacing="2px"
                textAlign={{ base: "center", md: "left" }}
                color={brandColor}
                fontSize={{ base: "xs", md: "md" }}
                w="100%"
                display={{ base: "none", lg: "flex" }}
                mb="10px"
              >
                YOUR REACT DASHBOARD LIBRARY
              </Text>
              <Text
                as="h2"
                fontWeight="800"
                color={textColor}
                display={{ base: "none", lg: "flex" }}
                fontSize={{ base: "22px", md: "40px" }}
                lineHeight={{ base: "28px", md: "48px" }}
                mb="20px"
                maxW={{ base: "100%", md: "flex" }}
                textAlign={{ base: "center", md: "left" }}
              >
                Improve your development process tremendously faster
              </Text>
              <Text
                color="gray.600"
                fontSize={{ base: "md", md: "md", xl: "lg" }}
                fontWeight="500"
                letterSpacing="0px"
                textAlign={{ base: "center", md: "left" }}
                lineHeight={{ base: "24px", md: "30px" }}
                display={{ base: "none", lg: "flex" }}
                w={{ base: "100%", md: "100%" }}
                mb="30px"
              >
                Horizon UI PRO gives you access to a pack with over 400+
                frontend individual elements, like buttons, inputs, navbars, nav
                tabs, cards, or alerts, giving you the freedom of choosing and
                combining.
              </Text>
              <SimpleGrid columns={{ base: "1", md: "2", xl: "2" }} gap="24px">
                <Flex>
                  <Icon
                    as={MdTrendingUp}
                    color={brandColor}
                    w="26px"
                    h="26px"
                    me="10px"
                  />
                  <Text
                    color={brandColor}
                    fontWeight="600"
                    fontSize="md"
                    letterSpacing="0px"
                  >
                    Lifetime free updates & growing components library
                  </Text>
                </Flex>
                <Flex>
                  <Icon
                    as={MdBolt}
                    color={brandColor}
                    w="26px"
                    h="26px"
                    me="10px"
                  />
                  <Text
                    color={brandColor}
                    fontWeight="600"
                    fontSize="md"
                    letterSpacing="0px"
                  >
                    Lightning-fast user
                    <br /> journey & experience
                  </Text>
                </Flex>
                <Flex>
                  <Icon
                    as={MdLayers}
                    color={brandColor}
                    w="26px"
                    h="26px"
                    me="10px"
                  />
                  <Text
                    color={brandColor}
                    fontWeight="600"
                    fontSize="md"
                    letterSpacing="0px"
                  >
                    Dashboards, NFTs, settings, marketing and many more
                  </Text>
                </Flex>
                <Flex>
                  <Icon
                    as={MdOutlineMonetizationOn}
                    color={brandColor}
                    w="26px"
                    h="26px"
                    me="10px"
                  />
                  <Text
                    color={brandColor}
                    fontWeight="600"
                    fontSize="md"
                    w="80%"
                    letterSpacing="0px"
                  >
                    14-day money back guaranteed
                  </Text>
                </Flex>
              </SimpleGrid>
            </Box>
          </Flex>
          <Flex
            w="max-content"
            position="relative"
            ms={{ base: "0px" }}
            mt={{ base: "40px", md: "40px", lg: "0px" }}
          >
            <Flex
              position="absolute"
              bg="linear-gradient(107.34deg, #3D1DFF 11.19%, #6147FF 26.5%, #D451FF 42.89%, #EC458D 62.48%, #FFCA8B 79.58%)"
              left="52%"
              top="50%"
              w={{ base: "100px", lg: "180px", xl: "180px" }}
              h={{ base: "100px", lg: "180px", xl: "180px" }}
              filter="blur(124px)"
              zIndex={"1"}
              transform="translate(-50%, -50%)"
            />
            <Image
              alt="react admin components"
              zIndex={"2"}
              src={example1}
              me={{ xl: "-20px" }}
              w={{ base: "330px", md: "660px", lg: "490px", xl: "560px" }}
              maxW={{ base: "330px", md: "660px", lg: "490px", xl: "560px" }}
            />
          </Flex>
        </Flex>

        {/* Huge Pack */}
        <Flex
          direction={{ base: "column-reverse", lg: "row" }}
          mb={{ base: "30px", lg: "120px" }}
          width="100%"
        >
          <Flex
            w="max-content"
            position="relative"
            me={{ base: "30px" }}
            mb={{ base: "40px", md: "40px", lg: "0px" }}
          >
            <Flex
              position="absolute"
              bg="linear-gradient(107.34deg, #3D1DFF 11.19%, #6147FF 26.5%, #D451FF 42.89%, #EC458D 62.48%, #FFCA8B 79.58%)"
              left="52%"
              top="50%"
              w={{ base: "100px", lg: "180px", xl: "180px" }}
              h={{ base: "100px", lg: "180px", xl: "180px" }}
              filter="blur(124px)"
              zIndex={"1"}
              transform="translate(-50%, -50%)"
            />
            <Image
              alt="react login page template"
              zIndex={"2"}
              src={example2}
              ms={{ lg: "-20px" }}
              w={{ base: "350px", md: "660px", lg: "400px", xl: "585px" }}
              maxW={{ base: "350px", md: "660px", lg: "400px", xl: "585px" }}
            />
          </Flex>
          <Flex
            direction="column"
            width="stretch"
            my="auto"
            alignItems={{ base: "center", md: "left" }}
          >
            <Box w="100%">
              <Text
                as="h3"
                fontWeight="700"
                letterSpacing="2px"
                textAlign={{ base: "center", md: "left" }}
                color={brandColor}
                fontSize={{ base: "xs", md: "md" }}
                w="100%"
                mb="10px"
              >
                HUGE PACK OF 44+ FULL CODED PAGE TEMPLATES
              </Text>
              <Text
                as="h2"
                fontWeight="800"
                color={textColor}
                fontSize={{ base: "22px", md: "40px" }}
                lineHeight={{ base: "28px", md: "48px" }}
                mb="20px"
                maxW={{ base: "100%", md: "flex" }}
                textAlign={{ base: "center", md: "left" }}
              >
                Full coded page examples
              </Text>
              <Text
                color="gray.600"
                fontSize={{ base: "md", md: "md", xl: "lg" }}
                fontWeight="500"
                letterSpacing="0px"
                lineHeight={{ base: "24px", md: "30px" }}
                w={{ base: "100%", md: "100%" }}
                mb="30px"
                textAlign={{ base: "center", md: "left" }}
                maxW={{ base: "100%", md: "80%", lg: "100%", xl: "100%" }}
              >
                We’ve made a premium pack of 44+ top-notch desktop & mobile
                react page examples like Login Page, Analytics, Financial, Sign
                In, Car Interface, Smart House, NFTs pages, Ecommerce, Tables,
                and so on.
              </Text>
              <SimpleGrid columns={{ base: "1", md: "2", xl: "2" }} gap="24px">
                <Flex>
                  <Icon
                    as={MdLayers}
                    color={brandColor}
                    w="26px"
                    h="26px"
                    me="10px"
                  />
                  <Text
                    color={brandColor}
                    fontWeight="600"
                    fontSize="md"
                    letterSpacing="0px"
                  >
                    Clean & structured <br /> coded components
                  </Text>
                </Flex>
                <Flex>
                  <Icon
                    as={MdInsights}
                    color={brandColor}
                    w="26px"
                    h="26px"
                    me="10px"
                  />
                  <Text
                    color={brandColor}
                    fontWeight="600"
                    fontSize="md"
                    letterSpacing="0px"
                  >
                    Available in the most used technologies & frameworks
                  </Text>
                </Flex>
                <Flex>
                  <Icon
                    as={MdDevices}
                    color={brandColor}
                    w="26px"
                    h="26px"
                    me="10px"
                  />
                  <Text
                    color={brandColor}
                    fontWeight="600"
                    fontSize="md"
                    letterSpacing="0px"
                  >
                    Full responsive desktop
                    <br />
                    and mobile examples
                  </Text>
                </Flex>
                <Flex>
                  <Icon
                    as={MdAddTask}
                    color={brandColor}
                    w="26px"
                    h="26px"
                    me="10px"
                  />
                  <Text
                    color={brandColor}
                    fontWeight="600"
                    fontSize="md"
                    w="80%"
                    letterSpacing="0px"
                  >
                    New pages & components almost every month
                  </Text>
                </Flex>
              </SimpleGrid>
            </Box>
          </Flex>
        </Flex>
        {/* Configurator */}
        <Flex direction={{ base: "column", lg: "row" }} width="100%">
          <Flex direction="column" width="stretch">
            <Box my="auto">
              <Badge
                display={{ base: "flex", lg: "none", xl: "flex" }}
                colorScheme="brand"
                bg="linear-gradient(15.46deg, #4A25E1 26.3%, #9C84FF 91.13%)"
                borderRadius="25px"
                color="white"
                textTransform={"none"}
                mx={{ base: "auto", md: "unset" }}
                letterSpacing="2px"
                px="12px"
                py="8px"
                w="max-content"
                lineHeight="100%"
                mb="30px"
              >
                EXCLUSIVELY IN PRO
              </Badge>
              <Text
                as="h3"
                fontWeight="700"
                letterSpacing="2px"
                color={brandColor}
                fontSize={{ base: "xs", md: "md" }}
                w="100%"
                textAlign={{ base: "center", md: "left" }}
                mb="10px"
              >
                MANY REACT DASHBOARD STYLES
              </Text>
              <Text
                as="h2"
                fontWeight="800"
                color={textColor}
                fontSize={{ base: "22px", md: "40px" }}
                lineHeight={{ base: "28px", md: "48px" }}
                mb="20px"
                textAlign={{ base: "center", md: "left" }}
                maxW={{ base: "100%", md: "80%", lg: "100%", xl: "80%" }}
              >
                Themes styles & modes based on your preferences.
              </Text>
              <Text
                color="gray.600"
                fontSize={{ base: "md", md: "md", xl: "lg" }}
                fontWeight="500"
                letterSpacing="0px"
                textAlign={{ base: "center", md: "left" }}
                lineHeight={{ base: "24px", md: "30px" }}
                w={{ base: "100%", md: "70%", lg: "100%", xl: "84%" }}
              >
                Define your project the way you like it by choosing your
                favorite theme modes, colors, sidebar states, contrast and so
                on.
              </Text>
              <Flex
                direction={"column"}
                zIndex="3"
                w={{ base: "100%", md: "450px" }}
              >
                <Flex direction={{ base: "column", md: "row" }} my="40px">
                  <Link href="#pricing">
                    <Button
                      variant="primary"
                      py="20px"
                      px="16px"
                      fontSize="sm"
                      borderRadius="45px"
                      me={{ base: "0px", md: "14px" }}
                      mb={{ base: "20px", md: "0px" }}
                      w={{ base: "100%", md: "210px" }}
                      h="54px"
                    >
                      Get started with PRO
                      <Icon
                        as={MdChevronRight}
                        color="white"
                        h="16px"
                        w="16px"
                      />
                    </Button>
                  </Link>
                  <Link
                    w={{ base: "100%", md: "unset" }}
                    isExternal
                    href="https://horizon-ui.com/chakra-pro"
                  >
                    <Button
                      variant="silver"
                      py="20px"
                      w={{ base: "100%", md: "unset" }}
                      px="30px"
                      fontSize="sm"
                      borderRadius="45px"
                      me={{ base: "0px", md: "14px" }}
                      mb={{ base: "10px", md: "0px" }}
                      h="54px"
                    >
                      Try it on live preview
                    </Button>
                  </Link>
                </Flex>
                <Text
                  textAlign="start"
                  color="#4A5568"
                  fontSize="sm"
                  mb={{ base: "60px", md: "0px" }}
                  lineHeight="24px"
                  letterSpacing="0px"
                  fontWeight="500"
                >
                  🤯 Did you know that more than 81.9% of all mobile and desktop
                  users use dark mode and love to have more customization
                  settings?
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex
            w="max-content"
            position="relative"
            ms={{ lg: "40px" }}
            mt={{ base: "0px", md: "120px", lg: "0px" }}
            alignSelf={{ base: "center", lg: "unset" }}
          >
            <Flex
              position="absolute"
              bg="linear-gradient(107.34deg, #3D1DFF 11.19%, #6147FF 26.5%, #D451FF 42.89%, #EC458D 62.48%, #FFCA8B 79.58%)"
              left="52%"
              top="50%"
              w={{ base: "200px", md: "400px", lg: "300px", xl: "400px" }}
              h={{ base: "200px", md: "400px", lg: "300px", xl: "400px" }}
              filter="blur(80px)"
              zIndex={"1"}
              transform="translate(-50%, -50%)"
            />
            <Image
              alt="react app dashboard style configurator"
              zIndex={"2"}
              src={main}
              w={{ base: "275px", md: "445px", lg: "360px", xl: "445px" }}
              borderRadius={{ base: "8px", md: "14px", lg: "14px" }}
              maxW={{ base: "275px", md: "445px", lg: "360px", xl: "445px" }}
            />
            <Image
              alt="react app dashboard style configurator contrast"
              zIndex={"2"}
              src={contrast}
              position={"absolute"}
              boxShadow="0px 24.3052px 140.97px -41.805px #8899AC"
              top={{ base: "-20px", md: "-56px", lg: "-50px", xl: "-70px" }}
              borderRadius={{ base: "8px", md: "14px", lg: "14px" }}
              w={{ base: "160px", md: "260px", lg: "220px", xl: "260px" }}
              h={{ base: "90px", md: "138px", lg: "118px", xl: "138px" }}
              right={{ base: "-36px", md: "-66px", lg: "-20px", xl: "-50px" }}
            />
            <Image
              zIndex={"5"}
              src={fullscreen}
              borderRadius={{ base: "8px", md: "14px", lg: "14px" }}
              w={{ base: "158px", md: "238px", lg: "198px", xl: "238px" }}
              h={{ base: "40px", md: "60px", lg: "50px", xl: "60px" }}
              position={"absolute"}
              boxShadow="0px 22.1516px 128.479px -14.1007px #8899AC"
              bottom={{ base: "122px", md: "210px", lg: "140px", xl: "120px" }}
              right={{ base: "-40px", md: "-60px" }}
            />
            <Image
              alt="react app dashboard style configurator color presets"
              zIndex={"2"}
              borderRadius={{ base: "8px", md: "14px", lg: "14px" }}
              w={{ base: "132px", md: "302px", lg: "232px", xl: "302px" }}
              h={{ base: "74px", md: "162px", lg: "122px", xl: "162px" }}
              src={colors}
              position={"absolute"}
              boxShadow="0px 24.3052px 140.97px -41.805px #8899AC"
              bottom={{ base: "-18px", md: "-62px", lg: "-46px", xl: "-70px" }}
              left={{ base: "-30px", md: "-80px", lg: "-70px", xl: "-100px" }}
            />
          </Flex>
        </Flex>
      </InnerContent>
    </Flex>
  );
}
