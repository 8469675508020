/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI PRO - v2.0.0 Beta
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// import bgDark from "assets/img/pro/features/bgDark.png";
// import Typescript from "assets/img/pro/pricing/Typescript.png";
// import Javascript from "assets/img/pro/pricing/Javascript.png";
// import NextJS from "assets/img/pro/pricing/NextJS.png";
import TailwindReact from "assets/img/pro/pricing/TailwindReact.png";
import TailwindJS from "assets/img/pro/pricing/tailwind-pro-js.png";
import TechCard from "components/techRadio";
import Typescript from "assets/img/pro/typescript-logo-image.png";
import Javascript from "assets/img/pro/javascript-logo-image.png";
import ChakraLogo from "assets/img/pro/chakra-logo.png";
import ReactLogo from "assets/img/pro/react-logo-image.png";
import Tailwind from "assets/img/pro/tailwindcss-logo-image.png";
import NextJS from "assets/img/pro/nextjs-logo-image.png";
// Custom components
import Card from "components/card/Card";
import { VSeparator } from "components/separator/Separator";
import IconBox from "components/icons/IconBox";
import { Cubes, Copy, Contact, InfinityIcon } from "components/icons/Icons";
import InnerContent from "layouts/innerContent";
import React, { useState } from "react";
import { MdAdd, MdChevronRight, MdPerson, MdGroup } from "react-icons/md";
import { TextLine } from "components/icons/Icons";

export default function Pricing(props) {
  const { ...rest } = props;

  const [version, setVersion] = useState("chakrats");
  // Chakra Color Mode
  // const buttonBorder = useColorModeValue("#E0E5F2", "white");
  const textColor = useColorModeValue("#120F43", "white");
  const textColorVAT = useColorModeValue("gray.600", "gray.600");
  const textColorSecondary = useColorModeValue("gray.600", "white");
  const textColorTertiary = useColorModeValue("gray.300", "white");
  const textGradient = useColorModeValue(
    "linear-gradient(119.25deg, #00E0FF 0%, #4318FF 50.21%, #FB18BC 78.6%)",
    "white"
  );
  const brandColor = useColorModeValue("brand.500", "white");
  const brandColorPrice = useColorModeValue("brand.500", "white");
  // const badgeColor = useColorModeValue('#E9E3FF', 'white');
  const card = useColorModeValue("#fff", "rgba(255, 255, 255, 0.05)");
  const cardTeam = useColorModeValue(
    "radial-gradient(58.11% 44.54% at 51.59% -9.61%, #B4B0FE 0%, #363285 22.92%, #110D5B 42.71%, #050327 88.54%)",
    "rgba(255, 255, 255, 0.1)"
  );
  const secCard = useColorModeValue("#F3F5FA", "rgba(255, 255, 255, 0.05)");
  const secCardTeam = useColorModeValue(
    "linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.06) 50.63%, rgba(255, 255, 255, 0.03) 100%)",
    "rgba(255, 255, 255, 0.09)"
  );
  const Paddle = window.Paddle;
  const openCheckout = (id) => {
    Paddle.Checkout.open(id);
  };

  return (
    <Flex
      id="pricing"
      bgSize="cover"
      w="100%"
      px="20px"
      direction={{ base: "column" }}
      pt={{ base: "30px", md: "40px", xl: "80px" }}
      pb={{ base: "100px", md: "100px", xl: "140px" }}
      overflow="hidden"
      position="relative"
      {...rest}
    >
      <InnerContent
        zIndex="1"
        maxW={{ base: "100%", md: "100%", xl: "1170px" }}
      >
        {/* Title */}
        <Flex w="100%" mb="40px" direction={{ base: "column" }}>
          <Flex
            direction="column"
            textAlign="start"
            px={{ base: "20px", md: "40px", xl: "0px" }}
            mb={{ base: "20px", lg: "20px" }}
            justify="center"
            align="center"
          >
            <Text
              as="h3"
              textAlign={{ base: "center", lg: "center" }}
              fontWeight="700"
              letterSpacing="2px"
              color={brandColorPrice}
              fontSize={{ base: "xs", md: "md" }}
              w="100%"
              mb="10px"
            >
              PRICING PLANS
            </Text>
            <Text
              as="h2"
              textAlign={{ base: "center", lg: "center" }}
              color={textColor}
              fontWeight="800"
              fontSize={{ base: "30px", md: "48px", lg: "48px", xl: "58px" }}
              lineHeight={{ base: "38px", md: "60px", lg: "60px", xl: "70px" }}
              w={{ base: "100%", md: "100%", lg: "70%", xl: "60%" }}
            >
              Choose the{" "}
              <Text as="span" position={"relative"}>
                right plan
                <Icon
                  position={"absolute"}
                  as={TextLine}
                  bottom={{
                    base: "-20px",
                    md: "-32px",
                    lg: "-30px",
                    xl: "-36px",
                  }}
                  left={{ base: "10%", md: "50%" }}
                  transform={{
                    base: "translate(-94%, 0px)",
                    md: "translate(-50%, 0px)",
                  }}
                  w={{ base: "180px", md: "272px" }}
                />
                <Icon
                  position={"absolute"}
                  as={TextLine}
                  bottom={{
                    base: "-20px",
                    md: "-32px",
                    lg: "-30px",
                    xl: "-36px",
                  }}
                  filter="blur(15px)"
                  left={{ base: "10%", md: "50%" }}
                  transform={{
                    base: "translate(-94%, 0px)",
                    md: "translate(-50%, 0px)",
                  }}
                  w={{ base: "180px", md: "272px" }}
                />
              </Text>{" "}
              for you and your business
            </Text>
          </Flex>
          {/* Toggle */}
          <Flex
            w="max-content"
            bg="white"
            boxShadow="14px 27px 45px rgba(112, 144, 176, 0.18)"
            borderRadius="16px"
            p={{ base: "10px", md: "30px" }}
            mt="20px"
            mb="40px"
            mx={{ base: "auto" }}
            maxW={{ base: "100%" }}
          >
            {/* NEW */}

            <Box maxW={{ base: "100%" }}>
              {/* Toggle */}
              <Flex
                direction={"column"}
                w="max-content"
                maxW={{ base: "100%" }}
                mx="auto"
              >
                <SimpleGrid
                  gap={{ base: "10px", md: "20px", lg: "30px" }}
                  columns={{ base: 1, md: 2, xl: 3 }}
                  zIndex="1"
                  spa
                  flexDirection={{ base: "column", lg: "row" }}
                  align="center"
                  position="relative"
                >
                  <TechCard
                    title="React + Chakra UI + TS"
                    onClick={() => setVersion("chakrats")}
                    active={version === "chakrats" ? true : false}
                    techs={
                      <Flex
                        mb="12px"
                        align={"center"}
                        gap={{ base: "8px", md: "14px" }}
                      >
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={ReactLogo}
                          alt="react logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={ChakraLogo}
                          alt="chakra ui logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={Typescript}
                          alt="typescript logo"
                        />
                      </Flex>
                    }
                  />
                  <TechCard
                    title="React + Chakra UI + JS"
                    onClick={() => setVersion("chakrajs")}
                    active={version === "chakrajs" ? true : false}
                    techs={
                      <Flex
                        mb="12px"
                        align={"center"}
                        gap={{ base: "8px", md: "14px" }}
                      >
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={ReactLogo}
                          alt="react logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={ChakraLogo}
                          alt="chakra ui logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={Javascript}
                          alt="javascript logo"
                        />
                      </Flex>
                    }
                  />
                  <TechCard
                    title="React + NextJS + Chakra UI + TS"
                    onClick={() => setVersion("nextchakra")}
                    active={version === "nextchakra" ? true : false}
                    techs={
                      <Flex
                        mb="12px"
                        align={"center"}
                        gap={{ base: "8px", lg: "14px" }}
                      >
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={ReactLogo}
                          alt="react logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={NextJS}
                          alt="nextjs logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={ChakraLogo}
                          alt="chakra ui logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={Typescript}
                          alt="typescript logo"
                        />
                      </Flex>
                    }
                  />
                  <TechCard
                    title="React + Tailwind CSS + TS"
                    onClick={() => setVersion("tailwindts")}
                    active={version === "tailwindts" ? true : false}
                    me={{ base: "0px", xl: "30px" }}
                    techs={
                      <Flex
                        mb="12px"
                        align={"center"}
                        gap={{ base: "8px", md: "14px" }}
                      >
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={ReactLogo}
                          alt="react logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={Tailwind}
                          alt="tailwindcss logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={Typescript}
                          alt="typescript logo"
                        />
                      </Flex>
                    }
                  />

                  <TechCard
                    title="React + Tailwind CSS + JS"
                    onClick={() => setVersion("tailwindjs")}
                    active={version === "tailwindjs" ? true : false}
                    techs={
                      <Flex
                        mb="12px"
                        align={"center"}
                        gap={{ base: "8px", md: "14px" }}
                      >
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={ReactLogo}
                          alt="react logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={Tailwind}
                          alt="tailwindcss logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={Javascript}
                          alt="javascript logo"
                        />
                      </Flex>
                    }
                  />
                  <TechCard
                    title="React + NextJS + Tailwind CSS + TS"
                    onClick={() => setVersion("tailwindnext")}
                    active={version === "tailwindnext" ? true : false}
                    techs={
                      <Flex
                        mb="12px"
                        align={"center"}
                        gap={{ base: "8px", lg: "14px" }}
                      >
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={ReactLogo}
                          alt="react logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={NextJS}
                          alt="nextjs logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={Tailwind}
                          alt="tailwindcss logo"
                        />
                        <Flex
                          justify={"center"}
                          align="center"
                          minW="23px"
                          h="23px"
                          p="4px"
                          borderRadius="100%"
                          bg="#E8E8FF"
                        >
                          <Icon as={MdAdd} w="15px" h="15px" color="white" />
                        </Flex>
                        <Image
                          maxW={{ base: "40px", md: "40px" }}
                          src={Typescript}
                          alt="typescript logo"
                        />
                      </Flex>
                    }
                  />
                </SimpleGrid>
              </Flex>
            </Box>
            {/* OLD */}
          </Flex>
          <Text
            fontSize={"sm"}
            textAlign="center"
            fontWeight="500"
            color="gray.600"
            letterSpacing="0px"
          >
            👆🏻 Before purchasing, please make sure you selected the correct
            technology/framework you want to get.
          </Text>
        </Flex>
        <SimpleGrid
          w="100%"
          columns={{ base: "1", lg: "2" }}
          gap="20px"
          mb="20px"
        >
          <Card
            borderRadius="16px"
            bg={card}
            maxW="575px"
            mx="auto"
            alignItems={{ base: "start", lg: "center" }}
            p={{ base: "16px", md: "40px" }}
            flexDirection="column"
          >
            <Flex mb={{ base: "30px", md: "50px" }}>
              <IconBox
                display={{ base: "none", md: "flex" }}
                w="70px"
                h="70px"
                minW="70px"
                minH="70px"
                me="28px"
                icon={
                  <Icon w="32px" h="32px" color="brand.500" as={MdPerson} />
                }
                bg="linear-gradient(180deg, #FBFBFF 0%, #CACAFF 100%)"
              />
              <Box>
                <Box
                  display="flex"
                  mb="5px"
                  flexDirection={{ base: "column", md: "row" }}
                  alignItems={{ base: "start", md: "center" }}
                >
                  <Text
                    fontSize="md"
                    letterSpacing="0px"
                    fontWeight="700"
                    color={textColor}
                  >
                    Personal
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    letterSpacing="0px"
                    ml={{ base: "0px", md: "6px" }}
                    color={textColorSecondary}
                  >
                    (for one developer)
                  </Text>
                </Box>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color={textColorSecondary}
                  letterSpacing="0px"
                >
                  A recommended single license for solo designers & developers
                  building high-end web applications.
                </Text>
              </Box>
            </Flex>
            {/* Secondary Card */}
            <Flex
              zIndex="1"
              p={{ base: "40px 18px", md: "40px 34px" }}
              borderRadius="16px"
              bg={secCard}
              direction="column"
              w="100%"
            >
              <Flex mb="30px" direction="row" justify="center">
                <Flex mb={{ base: "12px", md: "0px", lg: "12px", xl: "0px" }}>
                  <Text
                    bg={textGradient}
                    bgClip="text"
                    fontSize={{ base: "48px", md: "54px" }}
                    lineHeight="100%"
                    letterSpacing="0px"
                    fontWeight="extrabold"
                    // me="8px"
                  >
                    {version !== "nextchakra" && version !== "tailwindnext"
                      ? "$69"
                      : "$89"}
                  </Text>
                  {/* <Flex direction="column" justify="center">
                    <Text
                      textDecoration="line-through"
                      color={textColor}
                      fontWeight="500"
                      letterSpacing="0px"
                    >
                      reg. {version !== "nextchakra" && version !=="tailwindnext" ? "$109" : "$139"}
                    </Text>
                    <Text
                      color="green.500"
                      fontWeight="500"
                      fontSize="md"
                      letterSpacing="0px"
                    >
                      35% discount!
                    </Text>
                  </Flex> */}
                </Flex>
                <VSeparator mx="20px" />
                <Flex direction="column" justify="center">
                  <Text
                    color={textColor}
                    letterSpacing="0px"
                    fontWeight="700"
                    fontSize={{ base: "xs", md: "sm" }}
                  >
                    one-time payment
                  </Text>
                  <Text
                    color={textColorVAT}
                    letterSpacing="0px"
                    fontWeight="500"
                    fontSize={{ base: "xs", md: "sm" }}
                  >
                    VAT taxes included
                  </Text>
                </Flex>
              </Flex>
              <Button
                id="purchaseButton"
                w="100%"
                onClick={() =>
                  openCheckout(
                    version === "chakrats"
                      ? { product: 786931 }
                      : version === "chakrajs"
                      ? { product: 762852 }
                      : version === "nextchakra"
                      ? { product: 794926 }
                      : version === "tailwindjs"
                      ? { product: 815496 }
                      : version === "tailwindts"
                      ? { product: 820845 }
                      : { product: 854120 }
                  )
                }
                ms="auto"
                mt="auto"
                mb="18px"
                py="26px"
                fontSize="14px"
                bg="#120F43"
                color="white"
                _hover={{ bg: "#161159" }}
                _active={{ bg: "#0B083D" }}
                borderRadius="45px"
                lineHeight="14px"
                display="flex"
                letterSpacing="0px"
                alignItems="center"
              >
                Get started now with PRO
                <Icon as={MdChevronRight} mt="2px" />
              </Button>
              <Text
                textAlign="center"
                letterSpacing="0px"
                fontWeight="500"
                fontSize="sm"
              >
                Lifetime access. One-time payment. Free updates.
              </Text>
            </Flex>
            <Box
              h="2px"
              w="100%"
              bg="linear-gradient(90deg, rgba(14, 165, 233, 0) 0%, rgba(0, 224, 255, 0) 0.01%, #00E0FF 20.66%, #4318FF 52.95%, #FF18F6 83.16%, rgba(255, 24, 246, 0) 100%)"
              filter="blur(4px)"
              mb="50px"
            />
            {/* Features */}
            <Flex mb="50px">
              <Icon me="20px" w="32px" h="32px" color={brandColor} as={Cubes} />
              <Box>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  letterSpacing="0px"
                  color={textColorSecondary}
                >
                  <Text
                    as="span"
                    fontSize="md"
                    fontWeight="700"
                    color={textColor}
                    letterSpacing="0px"
                  >
                    400+ Components / elements
                  </Text>
                  — dark/light individual elements, like buttons, inputs,
                  navbars, cards, alerts, and so on... giving you the freedom of
                  choosing and combining.
                </Text>
              </Box>
            </Flex>
            <Flex mb="50px">
              <Icon me="20px" w="32px" h="32px" color={brandColor} as={Copy} />
              <Box>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  letterSpacing="0px"
                  color={textColorSecondary}
                >
                  <Text
                    as="span"
                    fontSize="md"
                    fontWeight="700"
                    color={textColor}
                    letterSpacing="0px"
                  >
                    42+ Example pages{" "}
                  </Text>{" "}
                  — Analytics Pages, Profile Pages, Ecommerce Pages, NFTs Pages,
                  Authentication, and so on. Save hundreds of hours trying to
                  create them from scratch.
                </Text>
              </Box>
            </Flex>
            <Flex>
              <Icon
                me="20px"
                h="36px"
                w="39px"
                color={brandColor}
                as={Contact}
              />
              <Box>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  letterSpacing="0px"
                  color={textColorSecondary}
                >
                  <Text
                    as="span"
                    fontSize="md"
                    letterSpacing="0px"
                    fontWeight="700"
                    color={textColor}
                  >
                    12 Months premium support{" "}
                  </Text>{" "}
                  — premium support with high priority for any problems with
                  Horizon UI PRO, via Discord or contact mail.
                </Text>
              </Box>
            </Flex>
          </Card>
          <Card
            borderRadius="16px"
            bg={cardTeam}
            maxW="585px"
            mx="auto"
            alignItems={{ base: "start", lg: "center" }}
            p={{ base: "16px", md: "40px" }}
            flexDirection="column"
          >
            <Flex mb={{ base: "30px", md: "50px" }}>
              <IconBox
                display={{ base: "none", md: "flex" }}
                w="70px"
                h="70px"
                minW="70px"
                minH="70px"
                me="28px"
                icon={<Icon w="32px" h="32px" color="white" as={MdGroup} />}
                bg="linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.06) 50.63%, rgba(255, 255, 255, 0.03) 100%)"
                boxShadow="inset 0px 4px 4px rgba(255, 255, 255, 0.2)"
              />
              <Box>
                <Box
                  display="flex"
                  mb="5px"
                  flexDirection={{ base: "column", md: "row" }}
                  alignItems={{ base: "start", md: "center" }}
                >
                  <Text
                    fontSize="md"
                    letterSpacing="0px"
                    fontWeight="700"
                    color="white"
                  >
                    Teams
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    letterSpacing="0px"
                    ml={{ base: "0px", md: "6px" }}
                    color="white"
                  >
                    (up to 10 developers)
                  </Text>
                </Box>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color="gray.200"
                  letterSpacing="0px"
                >
                  Perfect license for designers and developers teams working on
                  enterprise-level web applications.
                </Text>
              </Box>
            </Flex>
            {/* Secondary Card */}
            <Flex
              zIndex="1"
              p={{ base: "40px 18px", md: "40px 34px" }}
              borderRadius="16px"
              bg={secCardTeam}
              direction="column"
              shadow="0px 8px 25px -4px #FFFFFF4D inset"
              w="100%"
              alignItems={{ base: "center", md: "center" }}
            >
              <Flex
                mb="30px"
                direction={{
                  base: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                }}
              >
                <Flex mb={{ base: "12px", md: "0px", lg: "12px", xl: "0px" }}>
                  <Text
                    bg="white"
                    bgClip="text"
                    fontSize={{ base: "48px", md: "54px" }}
                    lineHeight="100%"
                    fontWeight="extrabold"
                    letterSpacing="0px"
                    me="8px"
                  >
                    {version !== "nextchakra" && version !== "tailwindnext"
                      ? "$189"
                      : "$229"}
                  </Text>
                  <Flex
                    direction="column"
                    justify="center"
                    display={{
                      base: "none",
                      md: "flex",
                      lg: "none",
                      xl: "flex",
                    }}
                  >
                    <Text
                      textDecoration="line-through"
                      color="white"
                      letterSpacing="0px"
                      fontWeight="500"
                    >
                      reg.{" "}
                      {version !== "nextchakra" && version !== "tailwindnext"
                        ? "$690"
                        : "$890"}
                    </Text>
                    <Text color="green.500" letterSpacing="0px">
                      {version !== "nextchakra" && version !== "tailwindnext"
                        ? "-72% discount"
                        : "-72% discount"}
                    </Text>
                  </Flex>
                </Flex>
                <VSeparator
                  display={{ base: "flex", md: "flex", lg: "flex", xl: "flex" }}
                  mx="14px"
                />
                <Flex direction="column" justify="center">
                  <Text
                    color="white"
                    letterSpacing="0px"
                    fontSize={{ base: "xs", md: "sm" }}
                    fontWeight="700"
                  >
                    one-time payment
                  </Text>
                  <Text
                    color={textColorTertiary}
                    fontSize={{ base: "xs", md: "sm" }}
                    fontWeight="500"
                    letterSpacing="0px"
                  >
                    VAT taxes included
                  </Text>
                </Flex>
              </Flex>
              <Button
                id="purchaseButton"
                w="100%"
                onClick={() =>
                  openCheckout(
                    version === "chakrats"
                      ? { product: 786932 }
                      : version === "chakrajs"
                      ? { product: 772348 }
                      : version === "nextchakra"
                      ? { product: 794929 }
                      : version === "tailwindjs"
                      ? { product: 815501 }
                      : version === "tailwindts"
                      ? { product: 820846 }
                      : { product: 854121 }
                  )
                }
                ms="auto"
                mt="auto"
                py="26px"
                mb="18px"
                fontSize="14px"
                bg="rgba(255, 255, 255, 0.14)"
                color="white"
                _hover={{ bg: "rgba(255, 255, 255, 0.25)" }}
                _active={{ bg: "rgba(255, 255, 255, 0.2)" }}
                borderRadius="45px"
                variant="teams"
                lineHeight="14px"
                display="flex"
                alignItems="center"
              >
                Get started now for your team
                <Icon as={MdChevronRight} mt="2px" />
              </Button>
              <Text
                textAlign="center"
                letterSpacing="0px"
                fontWeight="500"
                fontSize="sm"
                color={textColorTertiary}
              >
                Lifetime access. One-time payment. Free updates.
              </Text>
            </Flex>
            <Box
              h="2px"
              w="100%"
              // bg='linear-gradient(90deg, rgba(14, 165, 233, 0) 0%, rgba(0, 224, 255, 0) 0.01%, #00E0FF 20.66%, #4318FF 52.95%, #FF18F6 83.16%, rgba(255, 24, 246, 0) 100%)'
              // filter='blur(4px)'
              mb="50px"
            />
            {/* Features */}
            <Flex mb="50px">
              <Icon me="20px" w="32px" h="32px" color="white" as={MdGroup} />
              <Box>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  letterSpacing="0px"
                  color="gray.300"
                >
                  <Text
                    as="span"
                    fontSize="md"
                    fontWeight="700"
                    letterSpacing="0px"
                    color="white"
                  >
                    Get access for your entire team{" "}
                  </Text>{" "}
                  — team licenses include access for up to 10 people to
                  accommodate even the largest teams at your company.
                </Text>
              </Box>
            </Flex>
            <Flex mb="50px">
              <Icon me="20px" w="32px" h="32px" color="white" as={Contact} />
              <Box>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color="gray.300"
                  letterSpacing="0px"
                >
                  <Text
                    as="span"
                    fontSize="md"
                    letterSpacing="0px"
                    fontWeight="700"
                    color="white"
                  >
                    24 Months premium support{" "}
                  </Text>{" "}
                  — premium support with high priority for any problems with
                  Horizon UI PRO, via Discord or contact mail.
                </Text>
              </Box>
            </Flex>
            <Flex>
              <Icon
                me="20px"
                h="36px"
                w="40px"
                color="white"
                as={InfinityIcon}
              />
              <Box>
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color="gray.300"
                  letterSpacing="0px"
                >
                  <Text
                    as="span"
                    fontSize="md"
                    letterSpacing="0px"
                    fontWeight="700"
                    color="white"
                  >
                    Unlimited projects
                  </Text>{" "}
                  — create as much projects you want with your team/company
                  based on Horizon UI PRO.
                </Text>
              </Box>
            </Flex>
          </Card>
        </SimpleGrid>
        <Text
          color={textColor}
          fontSize={{ base: "md", md: "lg" }}
          letterSpacing="0px"
          fontWeight="700"
          textAlign="center"
          w="100%"
          mb="30px"
          mt="10px"
        >
          Looking for unlimited? Invest $60 more and unlock unlimited
          possibilities with our Enterprise License! 👇
        </Text>
        <Card
          borderRadius="16px"
          bg={card}
          alignItems={{ base: "start", lg: "center" }}
          flexDirection={{ base: "column", lg: "column" }}
          p={{ base: "16px", md: "32px" }}
          maxW={{ base: "100%", md: "565px", lg: "100%" }}
        >
          {/* <Text
            color={brandColorPrice}
            fontSize="xl"
            letterSpacing="0px"
            fontWeight="700"
            textAlign="center"
            w="100%"
            mb="30px"
          >
            Looking for unlimited? Invest $60 more and unlock unlimited
            possibilities with our Enterprise License.
          </Text> */}
          <Flex
            alignItems={{ base: "start", lg: "center" }}
            flexDirection={{ base: "column", lg: "row" }}
          >
            <Box
              minW={{ base: "100%", md: "100%", lg: "264px", "3xl": "264px" }}
              me={{ base: "20px", xl: "120px" }}
            >
              <Box
                display="flex"
                mb="5px"
                flexDirection={{ base: "column", md: "row" }}
                alignItems={{ base: "start", md: "center" }}
              >
                <Text
                  color={textColor}
                  fontSize="md"
                  letterSpacing="0px"
                  fontWeight="700"
                >
                  Enterprise
                </Text>
                <Text
                  fontSize="sm"
                  ml={{ base: "0px", md: "6px" }}
                  letterSpacing="0px"
                  color={textColorSecondary}
                  fontWeight="500"
                >
                  (for unlimited developers)
                </Text>
              </Box>
              <Flex>
                <Text
                  me="10px"
                  color={textColor}
                  fontSize="48px"
                  fontWeight="800"
                  letterSpacing="0px"
                >
                  {version !== "nextchakra" && version !== "tailwindnext"
                    ? "$249"
                    : "$289"}
                </Text>
                {/* <Flex justify="center" direction="column">
                  <Text
                    fontSize="md"
                    fontWeight="500"
                    textDecoration="line-through"
                    color={textColor}
                    letterSpacing="0px"
                  >
                    reg. {version !== "nextchakra" && version !=="tailwindnext" ? "$1029" : "$1069"}
                  </Text>
                  <Text
                    w="max-content"
                    fontSize="md"
                    fontWeight="500"
                    letterSpacing="0px"
                    color="green.500"
                  >
                    {version !== "nextchakra" && version !=="tailwindnext" ? "-75% discount" : "-75% discount"}
                  </Text>
                </Flex> */}
              </Flex>
            </Box>
            <Box
              me={{ base: "20px", xl: "98px" }}
              minW={{ base: "100%", md: "100%", lg: "445px", "3xl": "430px" }}
            >
              <Text
                color={textColor}
                fontSize="md"
                fontWeight="700"
                letterSpacing="0px"
                mb={{ base: "20px", lg: "0px" }}
              >
                Every feature from Teams Plan but for Unlimited Users.
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textColorSecondary}
                letterSpacing="0px"
                mb={{ base: "20px", lg: "0px" }}
              >
                Relevant for large-scale uses and extended redistribution
                rights. A license for large companies working on big projects.
              </Text>
            </Box>
            <Button
              id="purchaseButton"
              w={{ base: "300px", md: "180px", xl: "100%" }}
              ms="auto"
              fontSize="14px"
              border="1px solid"
              borderRadius="45px"
              bg={"transparent"}
              _hover={{ bg: "gray.100" }}
              _active={{ bg: "gray.100" }}
              py="26px"
              borderColor="gray.300"
              color={textColor}
              onClick={() =>
                openCheckout(
                  version === "chakrats"
                    ? { product: 786933 }
                    : version === "chakrajs"
                    ? { product: 772349 }
                    : version === "nextchakra"
                    ? { product: 794930 }
                    : version === "tailwindjs"
                    ? { product: 815502 }
                    : version === "tailwindts"
                    ? { product: 820847 }
                    : { product: 854122 }
                )
              }
            >
              Buy it now
              <Icon as={MdChevronRight} textColor={textColor} />
            </Button>
          </Flex>
        </Card>
      </InnerContent>
    </Flex>
  );
}
