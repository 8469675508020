/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v2.0.0 Beta
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  // useToast,
} from "@chakra-ui/react";
// Assets

// Custom components
import Card from "components/card/Card";

export default function TechCard(props) {
  const { title, active, onClick, techs, ...rest } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("#120F43", "white");
  const activeShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.22)",
    "none"
  );
  const Bg = useColorModeValue("white", "navy.700");

  const activeBg = useColorModeValue("#F7F9FF", "whiteAlpha.100");
  return (
    <Card
      transition="0.2s linear"
      borderRadius="16px"
      direction="column"
      border="2px solid"
      cursor="pointer"
      boxShadow={props.active === true ? activeShadow : "none"}
      _hover={{ background: Bg, boxShadow: activeShadow }}
      _focus={{ background: Bg, boxShadow: activeShadow }}
      _active={{ background: activeBg, boxShadow: activeShadow }}
      borderColor={active ? "brand.500" : "gray.200"}
      p="20px"
      px="12px"
      onClick={onClick}
      {...rest}
    >
      <Flex direction="column" align="center" justify="space-between" w="100%">
        {techs}
        <Text fontSize="18px" fontWeight={"700"} color={textColor}>
          {title}
        </Text>
      </Flex>
    </Card>
  );
}
